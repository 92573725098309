.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

select {
	-webkit-appearance: none;
	-moz-appearance: none; 
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.form-control{
  border: 1px solid #adadad !important;
  box-shadow: 1px 1px 1px #282c344a;
}

.filter-float {
  position: absolute;
  width: 600px;
  background-color: #fff;
  top: 0;
  right: 0;
  z-index: 100;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
}

.float-top {
  position: absolute;
  top: 20px;
  right: 20px;
}

.tabs {
  cursor: pointer;
}

.tabs-active {
  color: rgb(44, 95, 187);
}

.tabs:hover {
  color: rgb(44, 95, 187);
}

.show-tab-filters {
  display: block;
}

.hide-tab-filters {
  display: none;
}
